/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'block-in': {
    width: 20,
    height: 12,
    viewBox: '0 0 20 12',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M9.5 1a.5.5 0 01.5-.5h9a.5.5 0 010 1h-9a.5.5 0 01-.5-.5zm4 5a.5.5 0 01.5-.5h5a.5.5 0 010 1h-5a.5.5 0 01-.5-.5zM10 10.5a.5.5 0 000 1h9a.5.5 0 000-1h-9zM.5 6a.5.5 0 01.5-.5h6.793L5.646 3.354a.5.5 0 11.708-.708l3 3A.499.499 0 019.5 6v.003c0 .127-.05.254-.146.35l-3 3a.5.5 0 11-.708-.707L7.793 6.5H1A.5.5 0 01.5 6z" _fill="#fff"/>'
  }
})
